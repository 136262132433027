@tailwind base;
@tailwind components;
@tailwind utilities;

$font: #4a4a4a;

/* Material card styling */
.mat-card {
  box-shadow: 5px 5px 20px #0000001a !important;
  border-radius: 20px !important;
}

.grayCard {
  background-color: #f7f7f7 !important;
  box-shadow: none !important
}

/* Primary container styling */
.primary-container {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

/* Style of the logo */
.logo {
  height: 53px;
}

/* Centers the progress spinner horizontally */
.centered-progress-spinner {
  margin: auto;
}

/* Disabled input number arrows */
input[matinput]::-webkit-outer-spin-button,
input[matinput]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[matinput][type=number] {
  -moz-appearance: textfield;
}

/* Custom snack bar panel colors */
.snack-success {
  padding: 20px !important;
  background-color: #7fbd2d !important;
  color: #fef6f7 !important;
}

.snack-success .mat-simple-snackbar-action {
  color: #fef6f7 !important;
}

.snack-danger {
  padding: 20px !important;
  background-color: #dc3567 !important;
  color: #fef6f7 !important;
}

.snack-danger .mat-simple-snackbar-action {
  color: #fef6f7 !important;
}

.snack-warning {
  padding: 20px !important;
  background-color: #ffc107 !important;
  color: #343a39 !important;
}

.snack-warning .mat-simple-snackbar-action {
  color: #343a39 !important;
}

.snack-information {
  padding: 20px !important;
  background-color: #17a2b8 !important;
  color: #fef6f7 !important;
}

.snack-information .mat-simple-snackbar-action {
  color: #fef6f7 !important;
}

/* Displays text and icon in the same line */
.text-icon {
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  gap: 4px;
}

.cdk-overlay-pane {
  animation: fadeIn 0.6s;

  .mat-menu-panel {
    box-shadow: none;
    overflow-x: hidden;
  }
}

/* Button on primary color */
.primary-contrast-button {
  text-decoration: none;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  .hover-border {
    position: absolute;
    bottom: 5px;
    left: 50%;
    width: 0;
    height: 2px;
    transition: 0.4s;
  }

  &:hover .hover-border {
    width: 100%;
    transition: 0.4s;
    left: 0;
  }
}

.mat-subheading-1 {
  margin: 0 !important;
}

/* Override link styling */
a {
  text-decoration: none;
  position: relative;
  cursor: pointer;

  .hover-border {
    position: absolute;
    bottom: -5px;
    left: 50%;
    width: 0;
    height: 2px;
    transition: 0.4s;
  }

  &:hover {
    .hover-border {
      width: 100%;
      transition: 0.4s;
      left: 0;
    }
  }
}

/* Header */
.energyType {
  border-radius: 4px;
  font-weight: bold;

  .mat-icon {
    align-items: center;
    display: flex;
    font-size: 15px;
    height: 15px !important;
    width: 15px !important;
  }
}

.cdk-overlay-connected-position-bounding-box {
  right: 0 !important;

  hr {
    height: 2px;
    width: 100%;
    opacity: 0.1;
    margin-top: 25px;
  }
}

/********************
    Formular
********************/
// input
.mat-form-field-appearance-fill .mat-form-field-flex {
  border-radius: 5px !important;
  background: linear-gradient(to left, rgba(112, 112, 112, 0.08) 50%, rgba(112, 112, 112, 0.15) 50%) right !important;
  background-size: 200% !important;
  transition: 0.5s ease-out;
}

.input-search.mat-form-field-appearance-fill .mat-form-field-flex {
  background: white !important;
}

.mat-focused .mat-form-field-flex {
  background-position: left !important;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: -5px;
  font-size: 18px;
}


// Indikation
ul {
  list-style: none;
  padding: 0;

  li {
    margin: 8px 0;

    display: flex;
    align-items: center;

    span {
      border-radius: 100%;
      width: 30px;
      height: 30px;
      padding: 4px 8px;
      margin-right: 10px;
      text-align: center;
    }
  }
}


@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

// mat-icon
.mat-icon {
  color: $font;
}

.material-symbols-outlined,
.material-icons-outlined {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
}

.material-icons-mobile {
  font-size: 30px;
  width: 30px !important;
  height: auto !important;
  margin-right: 2px;
}

.material-icons-desktop {
  font-size: 40px;
  margin-right: 2px;
  width: 40px !important;
  height: auto !important;
}

.mat-icon.icon-post {
  width: auto;
  font-size: 62px;
  height: 62px;
  margin: 32px;
}

.mat-input-element ~ .mat-icon {
  position: absolute;
  right: 20px;
  top: -5px;
  font-size: 20px;
}

.amend {
  transition: 0.65s;
  font-weight: bold !important;
  padding: 8px 10px;
  border-radius: 16px;

  .mat-icon {
    transition: 0.6s ease;
    font-size: 18px;
    height: 18px;
  }
}


@media (min-width: 1200px) {
  .flex-even {
    flex: 1;
  }
}

.flex-even {
  flex: 1;
}


/* MAT TABLE */
.mat-cell, .mat-footer-cell, .font-small {
  font-size: 12px;
}

th.mat-header-cell:first-of-type, td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type {
  padding-left: 2px !important;
}

.tableHeadRow {
  padding-right: 15px !important;
}

.mat-paginator, .mat-paginator-page-size .mat-select-trigger {
  font-size: 12px;
}

.mat-paginator-container {
  padding: 0 !important;
  justify-content: space-between !important;
}

.mat-paginator-range-actions {
  width: 100% !important;
  justify-content: space-between;
}

.mat-paginator-page-size {
  display: none !important;
}

.mat-paginator-range-label {
  margin-left: 0 !important;
}

@media (min-width: 992px) {
  .mat-cell, .mat-footer-cell {
    font-size: 14px;
  }
  .mat-paginator-container {
    padding: 0 !important;
    justify-content: flex-end !important;
  }
  .mat-paginator-range-actions {
    width: 100% !important;
    justify-content: flex-end;
  }
}

/* Dashboard Spacing */
.dashboardRow {
  --bs-gutter-x: 2rem !important;
  --bs-gutter-y: 2rem !important;
}

/* Monthly Payment Header */
@media all and (min-width: 991px) {
  .monthlyPaymentHead {
    padding-right: 15px !important;
  }
}

@media all and (min-width: 768px) {
  .nextPaymentWrapper {
    padding-right: 14px;
  }
}

.mat-flat-button, .mat-raised-button {
  //border-radius: 8px !important;
}

.mat-form-field-underline {
  width: 0 !important;
}

// Typo
.mainHeading {
  font-size: 14px !important;
  font-weight: 400 !important;
}

.sectionHeading {
  font-weight: 700 !important;
  font-size: 16px !important;
}

@media all and (min-width: 768px) {
  .mainHeading {
    font-size: 16px !important;
    font-weight: 400 !important;
  }
  .sectionHeading {
    font-weight: 700 !important;
    font-size: 18px !important;
  }
}

// Accordion
.mat-expansion-panel {
  box-shadow: none !important;
  border-bottom: 2px solid #efefef !important;
  border-radius: 0 !important;

  &:first-of-type {
    border-top: 2px solid #efefef !important;
  }

  .mat-expansion-panel-header, .mat-expansion-panel-body {
    padding-left: 5px !important;
    padding-right: 15px !important;
  }
}

.mat-expansion-panel-spacing {
  margin: 0 !important;
}

// disable bootstrap smooth scrolling
html {
  scroll-behavior: auto !important;
}

// overwrite mat spinner color
.daily-price-spiral circle {
  stroke: #EBEBEB !important;
}

// dashboard same height
@media all and (min-width: 1024px) {
  .dashboardItem {
    min-height: 160px;
  }
}

.mat-form-field-wrapper {
  padding: 0 !important;
}

.mat-form-field-subscript-wrapper {
  position: relative !important;
}

.mat-error .mat-icon {
  display: none !important;
}

//CUSTOM CSS ORDER STEPPER
.orderStepper {
  pointer-events: none;

  .mat-stepper-vertical-line::before {
    border-color: #EBEBEB !important;
  }

  .mat-step-icon {
    background-color: #EBEBEB !important;
    z-index: 5;
  }

  .mat-step-icon .mat-step-icon-content {
    color: white;
    left: 52%;
    display: none;
  }

  .mat-stepper-vertical-line::before {
    left: -2px;
    border-left-width: 4px;
    border-left-style: solid;
  }

  .mat-step-header {
    padding-top: 16px;
    padding-bottom: 10px;
    padding-left: 0;
  }

  .mat-vertical-content-container {
    margin-left: 12px;
  }
}

.mat-tab-nav-bar, .mat-tab-header {
  border-bottom: 0 !important;
}

.tabs .mat-elevation-z4 {
  box-shadow: none !important;
}

.mat-dialog-container {
  @apply rounded-2xl #{!important};
}

.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: $font !important;
}


.orderCorrection .mat-horizontal-content-container {
  @apply flex-1 flex flex-col justify-between px-2;
  .mat-horizontal-stepper-content[aria-expanded="true"] {
    @apply flex-1 flex flex-col justify-between pt-6;
  }
}

.orderCorrection {
  .mat-dialog-container {
    @apply rounded-none shadow-none md:rounded-2xl #{!important};
  }
}

.breadcrumb {
  span {
    color: #383838;
  }

  mat-icon {
    color: #C1C1C1;
  }
}

.load-more-button {
  background-color: #383838 !important;
  color: white !important;
}

.captcha {
  font-family: "Comic Sans MS", cursive, sans-serif !important;
  font-size: 24px;
  letter-spacing: 10px;
  background: rgba(112, 112, 112, 0.03);
  padding: 16px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  user-select: none;
}

.single .mat-card {
  @apply w-full md:w-1/2 lg:w-1/3;
}

.double {
  @apply overflow-x-auto lg:overflow-x-hidden;
  .mat-card {
    @apply flex-none;
    width: calc(80vw - 3rem);
    @media all and (min-width: 768px) {
      @apply w-1/2 flex-auto;
    }
    @media all and (min-width: 1024px) {
      @apply w-1/3 flex-none;
    }
  }
}

.triple {
  @apply overflow-x-auto lg:overflow-x-hidden;
  .mat-card {
    @apply flex-none;
    width: calc(80vw - 3rem);
    @media all and (min-width: 768px) {
      width: calc(768px / 2 - 3rem);
    }
    @media all and (min-width: 1024px) {
      @apply w-1/3 flex-auto;
    }
  }
}

.scrollable {
  @apply overflow-x-auto;
  .mat-card {
    @apply flex-none;
    width: calc(80vw - 3rem);
    @media all and (min-width: 768px) {
      width: calc(768px / 2 - 3rem);
    }
    @media all and (min-width: 1024px) {
      width: calc(1024px / 2 - 3rem);
    }
    @media all and (min-width: 1280px) {
      width: calc(1280px / 3 - 3rem);
    }
    @media all and (min-width: 1536px) {
      width: calc(1536px / 3 - 3rem);
    }
  }
}

.hide-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.icon-size-contact {
  width: 32px !important;
  height: 32px !important;
  font-size: 32px !important;
}

.contact {
  .mat-checkbox-inner-container {
    margin-top: 2px !important;
  }

  .mat-expansion-panel-header {
    height: auto !important;
    padding: 12px 0;
  }
}

/*************************
 ***   carousel ***
 ***************************/
.slick-dots {
  padding: 0;
  text-decoration: none;
  display: flex;
  justify-content: center;
  list-style: none;
  margin-top: 30px;
}

.slick-dots li.active button {
  border-radius: 5px;
  width: 30px;
  opacity: 1;
  background: $font;
}

.slick-dots li button {
  border-radius: 90%;
  background: $font;
  padding: 0;
  height: 11px;
  width: 11px;
  opacity: 0.25;
  margin: 4px;
  transition: background 0.25s ease, width 0.25s ease;
  color: transparent;
}

.custom-fab-button {
  background-color: #F5F5F5;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  border: 0.5px solid #E2E2E2;
  width: 34px;
  height: 34px;
}

.text-font {
  color: $font !important;
}

.scroll-container {
  display: flex;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
