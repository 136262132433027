@use '@angular/material' as mat;
@use '../../../app/modules/history/components/history-entry.component' as timeline-component;

@import "./fonts/material-icons/material-icons.scss";
@import "./fonts/roboto/roboto.scss";
@import "@angular/material/theming";

/* Primary color palette */
$primary-palette: (
  50 : #f2f8e8,
  100 : #dfedc6,
  200 : #cae1a0,
  300 : #b5d47a,
  400 : #a5cb5e,
  500 : #95c241,
  600 : #8dbc3b,
  700 : #82b432,
  800 : #78ac2a,
  900 : #679f1c,
  A100 : #edffd7,
  A200 : #d5ffa4,
  A400 : #beff71,
  A700 : #b2ff58,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

/* Accent color palette */
$accent-palette: (
  50 : #fef3e4,
  100 : #fce2bb,
  200 : #facf8d,
  300 : #f8bc5f,
  400 : #f7ad3d,
  500 : #f59f1b,
  600 : #f49718,
  700 : #f28d14,
  800 : #f08310,
  900 : #ee7208,
  A100 : #ffffff,
  A200 : #ffefe3,
  A400 : #ffd1b0,
  A700 : #ffc296,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

/* Theming */
$ad-primary: mat.define-palette($primary-palette, 500);
$ad-accent: mat.define-palette($accent-palette, A200, A100, A400);

/* Color */
$primary-color: mat.get-color-from-palette($primary-palette, 500);
$primary-color-faded: mat.get-color-from-palette($primary-palette, 50);
$primary-color-category: mat.get-color-from-palette($primary-palette, 100);
$secondary-color: mat.get-color-from-palette($accent-palette, 500);
$secondary-color-faded: mat.get-color-from-palette($accent-palette, 50);
$hover-color: mat.get-color-from-palette($primary-palette, 50);
$lighten-primary-color: mat.get-color-from-palette($primary-palette, 200);
$gradient-light: mat.get-color-from-palette($primary-palette, 300);
$gradient-dark: mat.get-color-from-palette($primary-palette, 800);

/* Fonts */
$ad-typo: mat.define-typography-config(
  $font-family: Roboto,
  $headline: mat.define-typography-level(25px, 27px, 700),        /* H1 */
  $title: mat.define-typography-level(18px, 25px, 500),           /* H2 */
  $subheading-2: mat.define-typography-level(18px, 25px, 500),    /* H3 */
  $subheading-1: mat.define-typography-level(18px, 25px, 700),    /* H4 */
  $body-1: mat.define-typography-level(16px, 22px, 400),          /* Base body text */
  $body-2: mat.define-typography-level(16px, 22px, 500),          /* Bold body text */
  $button: mat.define-typography-level(16px, 22px, 700)          /* Buttons and anchors */
);

$ad-theme: mat.define-light-theme((
  color: (
    primary: $ad-primary,
    accent: $ad-accent
  ),
  typography: $ad-typo,
));

@include mat.core($ad-typo);
@include mat.all-component-themes($ad-theme);

@include timeline-component.theme($ad-theme);

@media (max-width : 600px) {
  $ad-typo: mat.define-typography-config(
    $font-family: Roboto,
    $headline: mat.define-typography-level(22px, 27px, 700),        /* H1 */
    $title: mat.define-typography-level(16px, 25px, 500),           /* H2 */
    $subheading-2: mat.define-typography-level(16px, 25px, 500),    /* H3 */
    $subheading-1: mat.define-typography-level(16px, 25px, 700),    /* H4 */
    $body-1: mat.define-typography-level(14px, 22px, 400),          /* Base body text */
    $body-2: mat.define-typography-level(14px, 22px, 500),          /* Bold body text */
    $button: mat.define-typography-level(14px, 22px, 700)          /* Buttons and anchors */
  );
  @include mat.core($ad-typo);
}

/* Basic Typo Color */
$font: #4a4a4a;
$error-color: red;

/* Material card styling */
.mat-card {
  background: white;
}

.mat-card,
.mat-card-title,
.mat-card-subtitle,
body {
  color: $font;
}

/* Button */
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  color: white;
}

/* Primary container styling */
.primary-container {
  background-color: white;
  color: $font;
}

/* Button on primary color */
.primary-contrast-button {
  color: #707070;
  .hover-border {
    background-color: $primary-color;
  }
}

.mat-subheading-1 {
  color: #707070;
}

.font-weight-bold {
  font-weight: bold !important;
}

.color-thema {
  color: white;
}

.primary-background {
  background-color: $primary-color;
}

.mat-icon.mat-primary {
  color: $primary-color;
}

/* Override link styling */
a {
  color: $primary-color;
  .hover-border {
    background-color: $primary-color;
  }
  &:hover {
    color: $primary-color;
  }
}

/* Styling for secondary contrast color */
.mat-button.mat-secondary-contrast,
.mat-stroked-button.mat-secondary {
  color: $font;
}

.mat-icon-button.mat-secondary-contrast {
  color: $font;
}

.mat-icon-button:hover {
  color: $primary-color;
}
/* Header */
.energyType {
  background: transparent linear-gradient(180deg, $gradient-light 0%, $gradient-dark 100%) 0% 0% no-repeat padding-box;
}

.cdk-overlay-connected-position-bounding-box {
  hr {
    border: 1px solid $font;
  }
}

/* Footer */
.linear-gradient {
  background: transparent linear-gradient(270deg, $gradient-light 0%, $gradient-dark 100%) 0% 0% no-repeat;
}

/********************
    Formular
********************/

.mat-select-value,
.mat-input-element,
.mat-form-field-label {
  color: $font !important;
}

// Error
.mat-form-field.mat-form-field-invalid .mat-form-field-label,
.mat-error,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker,
.mat-form-field-invalid .mat-input-element,
span.error {
  color: $error-color !important;
  caret-color: $error-color !important;
}

.mat-form-field-appearance-fill .mat-form-field-subscript-wrapper {
  padding: 0 !important;
}

.mat-error,
span.error {
  .mat-icon {
    color: $error-color;
  }
}

.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: $error-color;
}

// Indikation
.hints {
  .mat-card-title {
    color: $primary-color;
  }
}

ul {
  li {
    span {
      border: 2px solid $primary-color;
      color: $primary-color;
    }
  }
}

.mat-icon.icon-post {
  color: $primary-color;
}

.amend {
  color: $lighten-primary-color;
  .mat-icon {
    color: $lighten-primary-color;
  }
  &:hover {
    background: $hover-color;
    .mat-icon {
      color: $primary-color;
    }
  }
}

// HomeLink
.homeLink {
  height: 50px;
  background: no-repeat url(./logos/logo.svg) center center;
  background-position-x: left;
  background-size: contain;
  margin-left: 1rem;
  @media all and (min-width: 640px) {
    height: 60px;
    background-position-x: center;
    margin-left: 0;
  }
}

//ContractHeader
.contractHeader {
  background: linear-gradient(0.25turn, #b5d47a, #679f1c);
  position: relative;
  .energySymbol {
    position: absolute;
    right: 10%;
    width: 75px;
    height: 85px;
    top: 0;
    &.electricity {
      background: no-repeat url(./logos/symbolElectricity.svg) top center;
    }
    &.gas {
      background: no-repeat url(./logos/symbolGas.svg) top center;
    }
  }
}

// Faded BG
.fadedBG {
  background: linear-gradient(0.25turn, $primary-color-faded, $secondary-color-faded);
}

// Color
.primaryColor {
  color: $primary-color;
}
.primaryColorFaded {
  background-color: $primary-color-faded;
}

//CUSTOM CSS ORDER STEPPER
.orderStepper {
  .mat-step-icon-selected {
    background-color: $primary-color !important;
  }
  .mat-step-label {
    opacity: .5;
  }
  .mat-step-label-selected {
    opacity: 1;
    .mat-subheading-1 {
      color: $primary-color;
    }
  }
  .mat-vertical-content {
    padding-bottom: 0;
  }
}

.primaryCategoryBackgroundColor {
  background-color: $primary-color-category;
}

.category-favourite {
  background-color: $primary-color;
  color: white;
  mat-icon{
    color: white !important;
  }

  mat-card-actions span {
    color: white;
  }
}

.border-primary-color {
  border-color: $primary-color
}

.tooltip-primary {
  background: $primary-color;
  font-size: 12px;
  line-height: 1.35;
}
