@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {

  $color-config: mat.get-color-config($theme);

  /* Non palette theme */
  $dark-gray: #707070;
  $gray: #c8c8c8;

  /* Primary */
  $primary-palette: map.get($color-config, 'primary');
  $primary-color: mat.get-color-from-palette($primary-palette, 500);
  $primary-contrast-color: mat.get-contrast-color-from-palette($primary-palette, 500);
  /* Started */
  .mat-primary-started-icon {
    color: $primary-contrast-color;
    background-color: $primary-color;
  }
  .mat-primary-started-title {
    color: $primary-color;
  }
  .mat-primary-started-expand-button {
    color: $primary-color;
  }
  .mat-primary-started-line {
    background-color: $gray;
  }
  /* Completed */
  .mat-primary-completed-icon {
    color: $primary-contrast-color;
    background-color: $primary-color;
  }
  .mat-primary-completed-title {
    color: $primary-color;
  }
  .mat-primary-completed-expand-button {
    color: $primary-color;
  }
  .mat-primary-completed-line {
    background-color: $primary-color;
  }
  /* Error */
  .mat-primary-error-icon {
    color: $primary-contrast-color;
    background-color: $dark-gray;
  }
  .mat-primary-error-title {
    color: $dark-gray;
  }
  .mat-primary-error-expand-button {
    color: $dark-gray;
  }
  .mat-primary-error-line {
    background-color: $gray;
  }
  /* Upcoming */
  .mat-primary-upcoming-icon {
    color: $primary-contrast-color;
    background-color: $gray;
  }
  .mat-primary-upcoming-title {
    color: $gray;
  }
  .mat-primary-upcoming-expand-button {
    color: $gray;
  }
  .mat-primary-upcoming-line {
    background-color: $gray;
  }
  /* Accent */
  $accent-palette: map.get($color-config, 'accent');
  $accent-color: mat.get-color-from-palette($accent-palette, 500);
  $accent-contrast-color: mat.get-contrast-color-from-palette($accent-palette, 500);
  /* Started */
  .mat-accent-started-icon {
    color: $accent-contrast-color;
    background-color: $accent-color;
  }
  .mat-accent-started-title {
    color: $accent-color;
  }
  .mat-accent-started-expand-button {
    color: $accent-color;
  }
  .mat-accent-started-line {
    background-color: $gray;
  }
  /* Completed */
  .mat-accent-completed-icon {
    color: $accent-contrast-color;
    background-color: $accent-color;
  }
  .mat-accent-completed-title {
    color: $accent-color;
  }
  .mat-accent-completed-expand-button {
    color: $accent-color;
  }
  .mat-accent-completed-line {
    background-color: $accent-color;
  }
  /* Error */
  .mat-accent-error-icon {
    color: $accent-contrast-color;
    background-color: $dark-gray;
  }
  .mat-accent-error-title {
    color: $dark-gray;
  }
  .mat-accent-error-expand-button {
    color: $dark-gray;
  }
  .mat-accent-error-line {
    background-color: $gray;
  }
  /* Upcoming */
  .mat-accent-upcoming-icon {
    color: $accent-contrast-color;
    background-color: $gray;
  }
  .mat-accent-upcoming-title {
    color: $gray;
  }
  .mat-accent-upcoming-expand-button {
    color: $gray;
  }
  .mat-accent-upcoming-line {
    background-color: $gray;
  }
  /* Warn */
  $warn-palette: map.get($color-config, 'warn');
  $warn-color: mat.get-color-from-palette($warn-palette, 500);
  $warn-contrast-color: mat.get-contrast-color-from-palette($warn-palette, 500);
  /* Started */
  .mat-warn-started-icon {
    color: $warn-contrast-color;
    background-color: $warn-color;
  }
  .mat-warn-started-title {
    color: $warn-color;
  }
  .mat-warn-started-expand-button {
    color: $warn-color;
  }
  .mat-warn-started-line {
    background-color: $gray;
  }
  /* Completed */
  .mat-warn-completed-icon {
    color: $warn-contrast-color;
    background-color: $warn-color;
  }
  .mat-warn-completed-title {
    color: $warn-color;
  }
  .mat-warn-completed-expand-button {
    color: $warn-color;
  }
  .mat-warn-completed-line {
    background-color: $warn-color;
  }
  /* Error */
  .mat-warn-error-icon {
    color: $warn-contrast-color;
    background-color: $dark-gray;
  }
  .mat-warn-error-title {
    color: $dark-gray;
  }
  .mat-warn-error-expand-button {
    color: $dark-gray;
  }
  .mat-warn-error-line {
    background-color: $gray;
  }
  /* Upcoming */
  .mat-warn-upcoming-icon {
    color: $warn-contrast-color;
    background-color: $gray;
  }
  .mat-warn-upcoming-title {
    color: $gray;
  }
  .mat-warn-upcoming-expand-button {
    color: $gray;
  }
  .mat-warn-upcoming-line {
    background-color: $gray;
  }
}

/* Mixins */
@mixin typography($theme) {
  $typography-config: mat.get-typography-config($theme);

  .my-carousel {
    font-family: mat.font-family($typography-config);
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);

  @if $color-config != null {
    @include color($theme);
  }
}
