@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url(roboto.woff2) format('woff2'), url(roboto.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url(roboto-v30-latin-500.woff2) format('woff2'), url(roboto-v30-latin-500.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url(roboto-v30-latin-700.woff2) format('woff2'), url(roboto-v30-latin-700.ttf) format('truetype');
}

